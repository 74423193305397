import * as React from "react"
import "./newsdetailcontent.scss"
import { createMarkup } from "../../../utils"

export default function NewsContentDetail({data}) {
  let showAuthor = true
  if(data?.postAuthor?.postAuthor?.authorImage?.localFile?.publicURL==null) {
    showAuthor = false
  }
  return (
    <>
      <section className="news-info">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-4 left-col">
              <div className="big-title h2">
                {data.title}
              </div>
              {(data.tags.nodes)
              ?
              (<div className="tag">
                {data.tags.nodes.map((tag,index) => (
                  <span key={index}>{tag.name}</span>
                ))}
              </div>)
              :''
              }
              <div className="date">{data.date}</div>
            </div>
            <div className="col-dt-8 right-col" dangerouslySetInnerHTML={createMarkup(data.content)}></div>
          </div>
          {showAuthor && 
          <div className="news-author-wrapper">
            <div className="col-group">
              <div className="col-dt-5">
                <div className="author-img-wrapper">
                  <img src={data?.postAuthor?.postAuthor?.authorImage?.localFile?.publicURL} alt={data?.postAuthor?.postAuthor?.authorImage?.altText || data.postAuthor.postAuthor.authorName} />
                </div>
              </div>
              <div className="col-dt-2">
                <div className="double-quotes">
                  <img src="/images/news/double-quotes.svg" alt="Double Quotes" />
                </div>
              </div>
              <div className="col-dt-5">
                <div className="author-info-wrapper">
                  <div className="title-combo">
                    <div className="name">{data.postAuthor.postAuthor.authorName}</div>
                    <div className="author-position">
                      <span>
                        {data.postAuthor.postAuthor.authorPosition}
                      </span>
                    </div>
                  </div>
                  <div className="parag" dangerouslySetInnerHTML={createMarkup(data.postAuthor.postAuthor.authorBio)}>
                  </div>
                </div>
              </div>
            </div>
          </div>
          } 
        </div>
      </section>
    </>
  )
}
