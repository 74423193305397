import * as React from 'react'
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Layout from '../components/layout'
import Seo from '../components/seo'
import NewsDetailFirst from '../components/FirstNewsDetail'
import NewsContentDetail from '../components/NewsContentDetail'
import RelatedNews from '../components/RelatedNews'
import Twitter from '../components/Twitter'

export default function NewsroomDetail({pageContext, location}) {
  
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let mainUrl= location.state?.parenturl
  let seo = pageContext?.node?.seo
  let translatedURI = pageContext?.node?.translations[0]?.uri
  if(!mainUrl){
     mainUrl = "/"+locale
  }

    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
            <NewsDetailFirst image={pageContext.node.featuredImage?.node} parentUrl={mainUrl}/>
            <NewsContentDetail data={pageContext.node}/>
            <RelatedNews/>
            <Twitter/>
        </Layout>
        </>
    )
}
