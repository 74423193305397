import React, { useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function RelatedNewsTitle() {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      relatednews_ar:translateString(language: AR, string: "RELATED NEWS")
      relatednews_en:translateString(language: EN, string: "RELATED NEWS")
    }
  }`)

  return (
    <>
      <div className="container">
        <div className="title">{string_translation(stringTranslations,"relatednews", locale)}</div>
      </div>
    </>
  )
}
