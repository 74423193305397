import React from "react"
import "./relatednews.scss"

import SpotlightSwiper from "../SpotlightSwiper"
import RelatedNewsTitle from "../RelatedNewsTitle"

export default function RelatedNews({data}) {
  return (
    <>
      <section className="spotlights related-news">
        <RelatedNewsTitle />
        <SpotlightSwiper />
      </section>
    </>
  )
}
